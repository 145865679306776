import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import gsap from "gsap";
window.gsap = gsap;

import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
window.ScrollTrigger = ScrollTrigger;

jQuery(document).ready(function($) {
	var windowWidth = $(window).width()

/* ======================  News Container - Custom Scrollbar ======================  */
	if($('.news-container').length) {
		new SimpleBar($('.news-container')[0]);
	}

/* ====================== Lets Talk CTA ======================  */
	var currentScrollPos = window.pageYOffset;

	if(currentScrollPos > 120) {
		$("header").addClass("scrolled");
	}

	window.onscroll = function() {
		var currentScrollPos = window.pageYOffset;

		if(currentScrollPos > 120) {
			$("header").addClass("scrolled");
		} else {
			$("header").removeClass("scrolled");
		}
	}

/* ====================== MOBILE NAV ======================  */
    $('.menu-toggle').on("click", function() {
        $(this).toggleClass('active');
        $('header nav').toggleClass('open');
        $('header').toggleClass('open');
        $('body').toggleClass('noscroll');
    })

    if(windowWidth < 1280) {
	    $('#menu-main-menu .menu-item-has-children').on("click", function() {
	       $(this).toggleClass('open');
	       $(this).find('.sub-menu').slideToggle();
	    })

	    $('.sub-menu a').on("click", function(event) {
	        event.stopPropagation();
	    });
	}

/* ====================== Init Fancybox ======================  */
	Fancybox.bind("[data-fancybox]", {
	});

/* ====================== Latest Posts Carousel ======================  */
	const testimonials_carousel = new Swiper('.testimonial-carousel .swiper', {
       	slidesPerView: 1,
       	speed: 600,
       	loop: true,
       	autoHeight: true,
       	watchSlidesProgress: true,
       	spaceBetween: 8,
       	effect: "fade",
       	fadeEffect: { crossFade: true },
       	autoplay: {
        	delay: 6000,
        	disableOnInteraction: false,
      	},
       	navigation: {
			nextEl: ".testimonial-carousel .swiper-next",
			prevEl: ".testimonial-carousel .swiper-prev",
		},
	});

/* ====================== Submit Gravity Form ======================  */
	$(document).on('gform_post_render', function(){
	     $('.gform_wrapper .btn').on('click', function() {
        	$(this).parents('.gform_wrapper').find('input[type=submit]').click();
        });
	});

/* ====================== Accordion ======================  */
	$('.accordion .row-title').on('click', function () {
		if($(this).hasClass('active')) {
			$(this).removeClass('active');
			$(this).next('.row-content').slideUp();
			$(this).next('.row-content').removeClass('active');
		} else {
			$('.accordion .row-title').removeClass('active');
			$('.row-content').slideUp();
			$('.row-content').removeClass('active');

			$(this).addClass('active');
			$(this).next('.row-content').slideDown();
			$(this).next('.row-content').addClass('active');
		}
    });

	$('.accordion .show-hidden-faqs').on('click', function () {
		$(this).parents('.block').find('.hidden-faqs').slideToggle();
		$(this).toggleClass('active');

		if($(this).hasClass('active')) {
			$(this).find('span').text('View less');
		} else {
			$(this).find('span').text('View more');
		}
	});



/* ====================== GSAP ======================  */
	if(windowWidth >= 1024) {
		let panels = gsap.utils.toArray(".panel")
		panels.forEach((panel, i) => {
		  ScrollTrigger.create({
		    trigger: panel,
		    start: "top top",
		    pin: i === panels.length -1 ? false : true,
		    end:"bottom top",
		    markers: false,
		    pinSpacing: false
		  });
		});
	}

	let headinganim = gsap.utils.toArray('h1:not(.no-anim),h2:not(.no-anim),h3:not(.no-anim),h4:not(.no-anim), h5:not(.no-anim), .homepage-masthead .content p')
	headinganim.forEach((item, index) => {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				once: true,
				start:"0 80%",
				toggleActions:"restart none none reset",
				end: "500",
				markers: false,
				toggleClass: 'animateelement'
			}
		});
	});

	let animate = gsap.utils.toArray('.animate')
	animate.forEach((item, index) => {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				once: true,
				start:"0 80%",
				toggleActions:"restart none none reset",
				end: "500",
				markers: false,
				toggleClass: 'animateelement'
			}
		});
	});

/* ====================== DRAG AND SCROLL DIAGRAM ======================  */
	const slider = document.querySelector('.simplebar-content-wrapper');
	let isDown = false;
	let startX;
	let scrollLeft;

	if($(slider).length > 0) {
		slider.addEventListener('mousedown', (e) => {
		  isDown = true;
		  slider.classList.add('active');
		  startX = e.pageX - slider.offsetLeft;
		  scrollLeft = slider.scrollLeft;
		});
		slider.addEventListener('mouseleave', () => {
		  isDown = false;
		  slider.classList.remove('active');
		});
		slider.addEventListener('mouseup', () => {
		  isDown = false;
		  slider.classList.remove('active');
		});
		slider.addEventListener('mousemove', (e) => {
		  if(!isDown) return;
		  e.preventDefault();
		  const x = e.pageX - slider.offsetLeft;
		  const walk = (x - startX) * 1; //scroll-fast
		  slider.scrollLeft = scrollLeft - walk;
		});
	}
});
